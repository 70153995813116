<template>
  <div v-if="isAuthorized">
    <p style="font-weight:bold; color:#635F5D">Top Dealer Analytics</p>
    <div style="margin-top:50px;">
      <TopDealer id="topDealer"></TopDealer>
    </div>
  </div>
</template>
<script>
import TopDealer from "../../components/AnalyticsCharts/TopDealer/TopDealer";
export default {
  components: { TopDealer },
  data() {
    return {
      isAuthorized: false,
    };
  },
  created() {
    if (!localStorage.getItem("jwt")) {
      this.$router.push({ path: "/login" });
    } else {
      this.isAuthorized = true;
    }
  },
};
</script>
