<template>
  <div :id="computedChartContainerId" class="TopDealerContainer">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div class="calenderContainerTopDealer">
      <CalendarDialog
        class="inputField"
        v-on:callback="startDateCallback"
        :default-date="startDate"
        field-label="Starting Date"
        :controller="startDateController"
      ></CalendarDialog>
      <CalendarDialog
        class="inputField"
        v-on:callback="endDateCallback"
        :default-date="endDate"
        field-label="Ending Date"
        :controller="endDateController"
      ></CalendarDialog>
      <div class="inputField">
        <v-select
          v-on:change="rankByCategoriesCallback"
          :items="rankByCategories"
          v-model="rankBy"
          label="Order by"
        ></v-select>
      </div>
      <div class="inputField">
        <v-select
          v-on:change="limitChangeCallback"
          :items="limitCategories"
          v-model="limit"
          label="Limit"
        ></v-select>
      </div>
      <div class="inputField">
        <v-checkbox
          @change="carStatusCallback"
          v-model="carStatus"
          label="Active Cars"
          value="active"
        ></v-checkbox>
        <v-checkbox
          @change="carStatusCallback"
          v-model="carStatus"
          label="Sold Cars"
          value="sold"
        ></v-checkbox>
      </div>
    </div>
    <svg width="1000" :id="computedChartId"></svg>
  </div>
</template>
<script>
import * as d3 from "d3v4";
import CalendarDialog from "../../CalendarDialog/CalendarDialog.vue";
import getAxios from "../../../../services/axios-get";
export default {
  name: "TopDealer",
  components: { CalendarDialog },
  props: {
    id: String,
  },
  data() {
    return {
      barWidth: -1,
      snackbar: false,
      snackMessage: "",
      width: null,
      height: 200,
      margin: {
        top: 70,
        left: 200,
        right: 100,
        bottom: 100,
      },
      topDealerByTimeData: null,
      topDealerByTimeChartContainer: null,
      xAxisGroup: null,
      yAxisGroup: null,
      xScale: null,
      yScale: null,
      startDate: "2020-12-01",
      endDate: "2021-6-01",
      startDateController: {},
      endDateController: {},
      limit: 30,
      limitCategories: [10, 30, 50, 100, 200, 300, 400],
      carStatus: ["active"],
      carStatusCategories: ["sold", "active"],
      rankBy: "carCount",
      rankByCategories: ["salePrice", "carCount"],
      xAxisLegend: "",
      yAxisLegend: "Car Dealer",
    };
  },
  computed: {
    computedChartId: function() {
      return "TopDealer" + this.id;
    },
    computedChartContainerId: function() {
      return "TopDealerContainer" + this.id;
    },
  },
  methods: {
    limitChangeCallback() {
      this.getTopDealersOverTime("update");
      this.height = this.limit * 20;
      d3.select("#" + this.computedChartId).attr("height", this.height + 100);
    },

    rankByCategoriesCallback() {
      this.getTopDealersOverTime("update");
    },

    carStatusCallback() {
      if (this.carStatus.length == 0) {
        this.showSnackBar("Kindly select atleast one status!!");
        this.carStatus = ["active"];
      }
      this.getTopDealersOverTime("update");
    },

    showSnackBar(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },

    startDateCallback(startDate) {
      if (new Date(startDate) > new Date(this.endDate)) {
        this.showSnackBar("Start Date should be less than End Date!!");
        this.startDateController.updateDateInCalendar(this.startDate);
        return;
      }
      this.startDate = startDate;
      this.getTopDealersOverTime("update");
    },

    endDateCallback(endDate) {
      if (new Date(endDate) < new Date(this.startDate)) {
        this.showSnackBar("End Date should be greater than Start Date!!");
        this.endDateController.updateDateInCalendar(this.endDate);
        return;
      }
      this.endDate = endDate;
      this.getTopDealersOverTime("update");
    },

    initGraph() {
      let chart = document.getElementById(this.computedChartContainerId);
      this.width = chart.clientWidth - 50;
      d3.select("#" + this.computedChartId).attr("width", this.width);
      let svg = d3.select("#" + this.computedChartId);
      this.topDealerByTimeChartContainer = svg
        .append("g")
        .attr("transform", `translate(${this.margin.left},${this.margin.top})`);
      this.xAxisGroup = this.topDealerByTimeChartContainer.append("g");
      this.yAxisGroup = this.topDealerByTimeChartContainer.append("g");
      this.renderChart();
    },

    loadLegend() {
      d3.select(`.x-axis-legend-TopDealer-${this.id}`).remove();
      d3.select(`.y-axis-legend-TopDealer-${this.id}`).remove();

      this.topDealerByTimeChartContainer
        .append("text")
        .attr("class", `x-axis-legend x-axis-legend-TopDealer-${this.id}`)
        .attr("transform", `translate( ${(this.width - 250) / 2}, -50)`)
        .text(this.rankBy == "carCount" ? "Car Count" : "Sale Price");

      this.topDealerByTimeChartContainer
        .append("text")
        .attr("class", `y-axis-legend y-axis-legend-TopDealer-${this.id}`)
        .attr("transform", `rotate(-90)`)
        .attr("x", -this.height / 2 - 50)
        .attr("y", -170)
        .text(this.yAxisLegend);
    },

    loadXAxis() {
      let extent = d3.extent(this.topDealerByTimeData, d => {
        if (this.rankBy == "carCount") return d["carCount"];
        if (this.rankBy == "salePrice") return d["amount"];
      });
      this.xScale = d3
        .scaleLinear()
        .range([0, this.width - 220])
        .domain([0, extent[1] + 50])
        .nice();

      this.xAxisGroup
        .attr("class", `x-axis-top-dealer-bar-chart-${this.id}`)
        .call(d3.axisTop(this.xScale));
    },

    loadYAxis() {
      console.log(this.topDealerByTimeData.map(d1 => d1["dealerName"]));
      this.yScale = d3
        .scaleBand()
        .range([0, this.height])
        .domain(this.topDealerByTimeData.map(d1 => d1["dealerName"]));
      this.yAxisGroup
        .attr("class", `y-axis-top-dealer-bar-chart-${this.id}`)
        .call(d3.axisLeft(this.yScale));
    },

    loadBarChart() {
      let self = this;
      let z = d3
        .scaleOrdinal()
        .range([
          "#98abc5",
          "#8a89a6",
          "#7b6888",
          "#6b486b",
          "#a05d56",
          "#d0743c",
          "#ff8c00",
        ])
        .domain(this.topDealerByTimeData.map(d => d["dealerName"]));

      let bars = this.topDealerByTimeChartContainer
        .selectAll(`.topDealerBars-${this.id}`)
        .data(this.topDealerByTimeData, d => {
          return d["dealerName"];
        });

      bars.exit().remove();

      bars
        .enter()
        .append("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseLeave)
        .attr("y", d => this.yScale(d["dealerName"]) + 5)
        .attr("class", `topDealerBars-${this.id}`)
        .merge(bars)
        .attr("x", 5)
        .attr("y", d => this.yScale(d["dealerName"]) + 5)
        .attr("height", this.yScale.bandwidth() - 5)
        .transition()
        .duration(2000)
        .attr("width", d => {
          if (this.rankBy == "carCount") return this.xScale(d["carCount"]);
          if (this.rankBy == "salePrice") return this.xScale(d["amount"]);
        })
        .attr("fill", d => z(d["dealerName"]));

      function onMouseOver(d) {
        //let category = this.parentElement.getAttribute("category");
        let x = parseInt(d3.select(this).attr("x"));
        let width = parseInt(d3.select(this).attr("width"));
        let xAxis = x + width + 10;
        let yAxis = parseInt(d3.select(this).attr("y")) - 25;
        if (xAxis + 200 > self.width - 220) {
          yAxis = yAxis + 50;
          xAxis = xAxis - 200;
        }
        self.topDealerByTimeChartContainer
          .append("foreignObject")
          .attr(
            "class",
            `TopDealerTooltipContainer-${self.id} TopDealerTooltipContainer`
          )
          .attr("x", xAxis)
          .attr("y", yAxis)
          .attr("width", 200)
          .attr("height", 80)
          .html(function() {
            return `
                            <div class="TopDealerTooltip">
                                <table style="border:1px solid black;">
                                    <tr style="font-size:11px">
                                        <td>
                                            <span style="text-align:center;padding:3px;"> Dealer Name  </span>
                                        </td>
                                        <td>
                                            <span style="text-align:center;padding:3px;"> ${
                                              d["dealerName"]
                                            } </span>
                                        </td>
                                    </tr>
                                    <tr style="font-size:11px">
                                        <td>
                                            <span style="text-align:center;padding:3px;"> ${
                                              self.rankBy == "carCount"
                                                ? "Car Count"
                                                : "Amount"
                                            } </span>
                                        </td>
                                        <td>
                                            <span style="text-align:center;padding:3px;"> ${
                                              self.rankBy == "carCount"
                                                ? d["carCount"]
                                                : d["amount"]
                                            }</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        `;
          });
      }

      function onMouseLeave() {
        d3.selectAll(`.TopDealerTooltipContainer-${self.id}`).remove();
      }
    },

    renderChart() {
      this.loadXAxis();
      this.loadYAxis();
      this.loadBarChart();
      this.loadLegend();
    },

    getTopDealersOverTime(mode) {
      let self = this;
      let url =
        process.env.VUE_APP_ANALYTICS_URL + "/api/v1/analytics/topDealer";
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
        category: this.rankBy,
        carStatus:
          this.carStatus.length == 1
            ? this.carStatus[0]
            : this.carStatus.join(","),
        limit: this.limit,
      };
      getAxios(url, params)
        .then(function(res) {
          self.topDealerByTimeData = res["data"]["data"];
          if (mode == "init") {
            self.initGraph();
          } else {
            self.renderChart();
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },

    updateSVG() {
      let chart = document.getElementById(this.computedChartContainerId);
      let currentWidth = chart.clientWidth - 50;
      this.width = currentWidth;
      d3.select("#" + this.computedChartId).attr("width", currentWidth);
      this.renderChart();
    },

    resizeChart() {
      window.addEventListener("resize", this.updateSVG);
    },
  },

  beforeMount() {
    let today = new Date();
    let before5Months = new Date();
    before5Months.setMonth(before5Months.getMonth() - 5);
    this.endDate = today.toISOString().substr(0, 10);
    this.startDate = before5Months.toISOString().substr(0, 10);
    this.height = this.limit * 20;
  },

  mounted() {
    this.getTopDealersOverTime("init");
    d3.select("#" + this.computedChartId).attr("height", this.height + 100);
    this.resizeChart();
  },

  destroyed() {
    window.removeEventListener("resize", this.updateSVG);
  },
};
</script>
<style lang="scss">
@import "./TopDealer.scss";
</style>
